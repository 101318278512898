import React from "react"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"
import {withPrefix} from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Gallery from "../../components/Gallery"
import Content from "../../components/Content"

const galImgs = [
  '/page-images/braniel-3606.jpg'
];

export default function AboutUs() {
  return (
    <Layout breadcrumbs={["value & commitment"]}>
 
    <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )} 
    />

    <Content>
      <p>We strive to use our creativity and experience to provide a cost-effective service from feasibility through to project completion and believe that a well designed building solution not only enhances the users experience but that there are clear project benefits in terms of cost in use and improved market values.</p>
      <p>	As a practice, we are committed to keeping up to date with the latest “best practice” design and management techniques and continually work to enhance all areas of our architectural knowledge including that of the wider building industry to ensure that we can provide the best possible advice and service to all our clients.</p>
      <p>	It is our experience that most clients wish to have a degree of certainty regarding the level of professional fees involved in their project prior to making a commitment.  It is our commitment therefore to prepare a comprehensive fixed-fee quote identifying both the level of service and what we would charge at each stage of the project.</p>
    </Content>

      <div class="region region-content-bottom">
        <MenuOne active="about-us"></MenuOne>
        <MenuTwo
          active="value-and-commitment"
          items={['../profile', '../design-approach', '../value-and-commitment', '../the-team']}>
        </MenuTwo>
      </div>

  </Layout>

  )
}
